/*다크모드*/
html.dark{
  background-color: black;
}
html.dark #root{
  background-color: black;
}

/* font-size로 rem 기본 반응형 */
html {
  font-size: 16px;

  @media (max-width: 800px) {
    font-size: 70%;
  }

  @media (max-width: 600px) {
    font-size: 50%;
  }
}


body {
    background-color: #F4F3EE;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 프리텐다드체(기본) */

@font-face {
	font-family: 'Pretendard Variable';
	font-weight: 45 920;
	font-style: normal;
	font-display: swap;
	src: local('Pretendard Variable'), url(/static/media/PretendardVariable.dc36554d.woff2) format('woff2-variations');
}

@font-face {
    font-family: 'Pretendard100';
    src: url(/static/media/Pretendard-Thin.483e1717.woff) format('woff');
    font-weight: 100;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard200';
    src: url(/static/media/Pretendard-ExtraLight.1104ea03.woff) format('woff');
    font-weight: 200;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard300';
    src: url(/static/media/Pretendard-Light.bfff3a54.woff) format('woff');
    font-weight: 300;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard400';
    src: url(/static/media/Pretendard-Regular.db095fbd.woff) format('woff');
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard500';
    src: url(/static/media/Pretendard-Medium.7bab4a8a.woff) format('woff');
    font-weight: 500;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard600';
    src: url(/static/media/Pretendard-SemiBold.46172012.woff) format('woff');
    font-weight: 600;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard700';
    src: url(/static/media/Pretendard-Bold.d93573b4.woff) format('woff');
    font-weight: 700;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard800';
    src: url(/static/media/Pretendard-ExtraBold.89373147.woff) format('woff');
    font-weight: 800;
    font-display: swap;
}

/* 다이어리체(포인트) */

@font-face {
    font-family: 'EF_Diary';
    font-weight: normal;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/earlyfont/EF_Diary.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/earlyfont/EF_Diary.eot?#iefix') format('embedded-opentype'),
         url('https://cdn.jsdelivr.net/gh/webfontworld/earlyfont/EF_Diary.woff2') format('woff2'),
         url('https://cdn.jsdelivr.net/gh/webfontworld/earlyfont/EF_Diary.woff') format('woff'),
         url('https://cdn.jsdelivr.net/gh/webfontworld/earlyfont/EF_Diary.ttf') format("truetype");
    font-display: swap;
}

@font-face {
    font-family: 'EF_Diary_bold';
    font-weight: bold;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/earlyfont/EF_Diary.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/earlyfont/EF_Diary.eot?#iefix') format('embedded-opentype'),
         url('https://cdn.jsdelivr.net/gh/webfontworld/earlyfont/EF_Diary.woff2') format('woff2'),
         url('https://cdn.jsdelivr.net/gh/webfontworld/earlyfont/EF_Diary.woff') format('woff'),
         url('https://cdn.jsdelivr.net/gh/webfontworld/earlyfont/EF_Diary.ttf') format("truetype");
    font-display: swap;
}

.app {
  padding: 2.5rem;
  text-align: center;
}

.container {
  margin: auto;
  width: 50%;
}

.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
.ReactTags__tagInput {
  width: 9.125rem;
  display: inline-block;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 3.625rem;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9375rem;
  width: 100%;
  border: 0.25rem dashed #000000;
  border-radius: 2.1875rem;
}

/* Styles for selected tags */
.ReactTags__selected {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactTags__selected {
  display: flex;
  align-items: center;
}

.ReactTags__selected span.ReactTags__tag {
  display: inline-block;
  padding: 0.3125rem;
  justify-content: center;
  align-items: center;
  width: 7.875rem;
  height: 3rem;
  opacity: 0.65;
  border: 0.25rem dashed #000000;
  background-color: white;
  border-radius: 2.1875rem;
  font-size: 0.9375rem;
  text-align: center;
  margin: 0.625rem;
}

.ReactTags__selected a.ReactTags__remove {
  color: black;
  margin-left: 0.3125rem;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: black;
  margin-top: auto;
}

.tag-wrapper ReactTags__tag {
  margin-top: auto;
}

